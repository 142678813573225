import { CSSProperties, useState } from "react";
import { useDispatch } from "react-redux";
import { setGlobalPopupElement } from "../../features/popupSlice";
import { CWImageType, CustomWidgetSettings } from "../../types/widget";
import ImagePopUpV2 from "../ImagePopUpV2";
import LinkForm, { LinkData, LinkFormV2 } from "../widgetFrom/LinkForm";
import classes from "./CWForm.module.css";
import PageSettingsForm from "./PageSettingsForm";
import SocialLinksForm from "./SocialLinksForm";
import ThemeSettingsForm from "./ThemeSettingsFrom";

export interface CustomFormProps {
	formItems: CustomWidgetSettings<any>;
	customForm?: boolean;
	name?: string;
	onSubmit?: (data: CustomWidgetSettings<any>) => void;
	title?: string;
	className?: string;
	style?: CSSProperties;
}

export const objectKeys = Object.keys as <T>(o: T) => (keyof T)[];

export const customFormSettings: {
	[key: string]: Object;
} = {
	"main logo": {
		image: "",
	},
	"app action button": {
		banner: {},
	},
};

const placeholderImg = "/img/image-placeholder.jpg";

const CustomFormSetting = ({
	name,
	settings,
	image,
	setImage = () => {},
	imagePopup = () => {},
}: {
	name: string;
	settings: any;
	image?: CWImageType;
	setImage?: (image: CWImageType) => void;
	imagePopup?: (type: "single" | "multiple") => void;
}) => {
	const dispatch = useDispatch();

	const formS = customFormSettings[name];

	const sItems = Object.keys(formS);

	return (
		<div>
			<div className="border-b pb-4">
				<div>{name}: </div>

				{sItems.map((key, index) => {
					const _image = image || settings?.image;
					console.log("image: ", image, _image, settings);

					switch (key) {
						case "image":
							return (
								<div key={index}>
									<div className="image__container mb-2">
										<img
											className="rounded-md mt-2"
											src={
												_image?.url
													? process.env
															.REACT_APP_IMAGE_URL +
													  _image?.url
													: placeholderImg
											}
											alt={_image?.name || name}
										/>
									</div>

									<button
										onClick={(e) => {
											e.preventDefault();
											imagePopup("single");
										}}
										className="imageSelector__btn mb-2">
										choose image
									</button>
								</div>
							);
						case "banner":
							return (
								<div key={index}>
									<div className="image__container mb-2">
										<img
											className="rounded-md mt-2"
											src={
												_image?.url
													? process.env
															.REACT_APP_IMAGE_URL +
													  _image?.url
													: placeholderImg
											}
											alt={_image?.name || name}
										/>
									</div>

									{!!_image?.url && (
										<LinkFormV2
											currnetLinkData={_image?.link}
											onUpdate={(data) => {
												console.log(data);

												setImage({
													..._image,
													link: data,
												});
											}}
										/>
									)}

									<button
										onClick={(e) => {
											e.preventDefault();

											dispatch(
												setGlobalPopupElement(
													<ImagePopUpV2
														single={true}
														onUpdate={(images) => {
															const arrayImages =
																images.map(
																	(
																		item: any
																	) => {
																		return {
																			image: item.path,
																			title: item.title,
																			link: {} as LinkData,
																		};
																	}
																);

															const item =
																arrayImages[0];

															setImage({
																url: item.image,
																name:
																	image?.name ||
																	item.title,
																link:
																	image?.link ||
																	({} as LinkData),
															});

															dispatch(
																setGlobalPopupElement(
																	null
																)
															);
														}}
														onClose={() => {
															dispatch(
																setGlobalPopupElement(
																	null
																)
															);
														}}
													/>
												)
											);
										}}
										className="imageSelector__btn mb-2">
										choose image
									</button>
								</div>
							);
					}

					return null;
				})}
			</div>
		</div>
	);
};

const customFormItems: {
	[key: string]: (props: CustomFormProps) => JSX.Element | null;
} = {
	"social links": (props) => <SocialLinksForm {...props} />,
	"page settings": (props) => <PageSettingsForm {...props} />,
	"theme settings": (props) => <ThemeSettingsForm {...props} />,
};

const CWForm = ({
	formItems,
	customForm = false,
	name = "",
	onSubmit = () => {},
	title,
	className,
	style,
}: CustomFormProps) => {
	const dispatch = useDispatch();

	const [imagePopupActive, setImagePopup] = useState(false);
	const [selectType, setSelectType] = useState<"signle" | "multiple">(
		"multiple"
	);

	const [images, setImages] = useState<CWImageType[]>([]);
	const [image, setImage] = useState<CWImageType>();

	const linkData = {
		hyperLinkText: "",
		url: "",
		urlType: "category",
	};

	const classNames = [classes.CWForm, className];

	const formItemsArray = objectKeys(formItems).map((key) => formItems[key]);

	console.log("formItemsArray, formItems", formItemsArray, formItems);

	const togglePupupHandler = (type: "OPEN" | "CLOSE", imageType?: string) => {
		// setImagePopup(type === "OPEN");
		dispatch(
			setGlobalPopupElement(
				type === "OPEN" ? (
					<ImagePopUpV2
						single={(imageType || selectType) === "signle"}
						onUpdate={(images) => {
							togglePupupHandler("CLOSE");

							const newImages: CWImageType[] = images.map(
								(image) => ({
									url: image.path || "",
									name: image.id + "",
									link: linkData,
								})
							);

							if ((imageType || selectType) === "signle") {
								setImage(newImages[0]);
								console.log("image", images);
							} else {
								setImages(newImages || []);
								console.log("images", images);
							}
						}}
						onClose={() => togglePupupHandler("CLOSE")}
					/>
				) : null
			)
		);

		if (type === "OPEN") {
			const div1 = document.querySelector(
				".widgetdetails__row--one"
			) as HTMLDivElement;
			const div2 = document.querySelector(
				".heade__container"
			) as HTMLDivElement;

			div1?.style.setProperty("z-index", "0");
			div2?.style.setProperty("z-index", "0");
		} else {
			const div1 = document.querySelector(
				".widgetdetails__row--one"
			) as HTMLDivElement;
			const div2 = document.querySelector(
				".heade__container"
			) as HTMLDivElement;

			div1?.style.removeProperty("z-index");
			div2?.style.removeProperty("z-index");
		}
	};

	const handleSubmit = () => {
		const data: CustomWidgetSettings<any> = {
			...formItems,
		};

		if (customForm) {
			onSubmit({
				image: image,
			} as any);
		} else {
			for (const item of formItemsArray) {
				switch (item?.field_key) {
					case "banners":
						data[item.field_key] = {
							...item,
							images: images || item.images,
						};
						break;
					case "banner":
						data[item.field_key] = {
							...item,
							image: image || item.image,
						};
						break;
				}
			}

			onSubmit(data);
		}
	};

	const handleBgColor = (bgColor: string, index: number) => {
		setImages((prev) => {
			const newImages = [...prev];

			newImages[index] = {
				...newImages[index],
				color: bgColor,
			};

			return newImages;
		});
	};

	const props = {
		formItems,
		customForm,
		name,
		onSubmit,
		title,
		className,
		style,
	};

	const key = name?.toLowerCase();

	if (customFormItems[key]) return customFormItems[key](props);
	else {
		for (const k in customFormItems) {
			if (key.toString()?.includes(k)) {
				return customFormItems[k](props);
			}
		}
	}

	return (
		<div className={classNames.join(" ")} style={style}>
			<form className="mainSliderForm__container">
				<h1 className="title capitalize">{title}</h1>

				{customForm && (
					<CustomFormSetting
						name={name}
						settings={formItems}
						image={image}
						imagePopup={(type) => {
							togglePupupHandler(
								"OPEN",
								type === "single" ? "signle" : "multiple"
							);
							setSelectType(
								type === "single" ? "signle" : "multiple"
							);
						}}
						setImage={(image) => {
							setImage(image);
						}}
					/>
				)}

				{customForm === false &&
					formItemsArray.map((formItem, index) => {
						const updatedImages =
							images.length > 0 ? images : formItem?.images || [];
						const updatedImage = image || formItem?.image;

						console.log("formItem", formItem);

						let formElement: JSX.Element | null = null;

						if (formItem?.field_key === "banners") {
							formElement = (
								<div>
									<div className="">
										{updatedImages.map((image, index) => {
											let src =
												"/img/image-placeholder.jpg";

											if (image.url)
												src =
													process.env
														.REACT_APP_IMAGE_URL +
													image.url;

											// console.log('src, image', src, image);

											return (
												<div
													key={index}
													className="image__container">
													<img
														className="rounded-md mt-2"
														src={src}
														alt={image.name}
													/>

													<div className="mt-3 border-b pb-4">
														{/* Background Color: {image.color} */}
														{image.color && (
															<div
																className="w-full h-20 rounded-md shadow-md mt-2"
																style={{
																	backgroundColor:
																		image.color,
																}}></div>
														)}
														<div className="mt-4 relative">
															<label
																htmlFor={`color-picker-${index}`}
																className="imageSelector__btn flex items-center justify-center cursor-pointer mt-6">
																Choose
																Background Color
															</label>

															<input
																id={`color-picker-${index}`}
																type="color"
																className="h-0 p-0 m-0 opacity-0 leading-[0] absolute bottom-0 left-0"
																value={
																	image.color
																}
																onChange={(
																	e
																) => {
																	console.log(
																		"e",
																		e.target
																			.value
																	);
																	handleBgColor(
																		e.target
																			.value,
																		index
																	);
																}}
															/>
														</div>
													</div>

													<LinkForm
														currnetLinkData={
															formItem.images?.[
																index
															]?.link || linkData
														}
														onUpdate={(data) => {
															setImages(
																(prev) => {
																	const newImages =
																		[
																			...(prev ||
																				formItem.images ||
																				[]),
																		];

																	newImages[
																		index
																	] = {
																		...updatedImages[
																			index
																		],
																		link: data,
																	};

																	return newImages;
																}
															);
														}}
													/>
												</div>
											);
										})}
									</div>
									<button
										onClick={(e) => {
											e.preventDefault();
											togglePupupHandler("OPEN");
										}}
										className="imageSelector__btn mb-2">
										choose images
									</button>

									{/* <LinkForm 
									currnetLinkData={linkData}
									onUpdate={handleLinkDataUpdate}
								/> */}
								</div>
							);
						} else if (formItem?.field_key === "banner") {
							formElement = (
								<div>
									<div className="">
										<div
											key={index}
											className="image__container mb-2">
											<img
												className="rounded-md mt-2"
												src={
													!!updatedImage?.url
														? process.env
																.REACT_APP_IMAGE_URL +
														  updatedImage.url
														: placeholderImg
												}
												alt={updatedImage?.name}
											/>

											{updatedImage && (
												<LinkFormV2
													currnetLinkData={
														formItem.image?.link ||
														linkData
													}
													onUpdate={(data) => {
														setImage((prev) => {
															if (prev) {
																const newImage =
																	{ ...prev };
																newImage.link =
																	data;
																return newImage;
															} else if (
																formItem.image
															) {
																const newImage =
																	{
																		...formItem.image,
																	};
																newImage.link =
																	data;
																return newImage;
															}
														});
													}}
												/>
											)}
										</div>
									</div>
									<button
										onClick={(e) => {
											e.preventDefault();
											togglePupupHandler(
												"OPEN",
												"signle"
											);
											setSelectType("signle");
										}}
										className="imageSelector__btn mb-2">
										choose images
									</button>

									{/* <LinkForm 
									currnetLinkData={linkData}
									onUpdate={handleLinkDataUpdate}
								/> */}
								</div>
							);
						}

						return (
							<div key={index}>
								<div className="border-b pb-4">
									<div>{formItem?.name}: </div>
									{formItem?.size && (
										<p className="text-[text-0.9rem]">
											Image Size: {formItem?.size}
										</p>
									)}

									{formElement}
								</div>
							</div>
						);
					})}

				<div>
					<button
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
						className="mainSliderForm__btn">
						save
					</button>
				</div>
			</form>

			{imagePopupActive && (
				<ImagePopUpV2
					single={selectType === "signle"}
					onUpdate={(images) => {
						togglePupupHandler("CLOSE");

						const newImages: CWImageType[] = images.map(
							(image) => ({
								url: image.path || "",
								name: image.id + "",
								link: linkData,
							})
						);

						if (selectType === "signle") {
							setImage(newImages[0]);
						} else {
							setImages(newImages || []);
						}
					}}
					onClose={() => togglePupupHandler("CLOSE")}
				/>
			)}
		</div>
	);
};

export default CWForm;
