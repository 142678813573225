import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { _axios } from "../../api";
import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../../components/SettingFormItems";
import { createSlug } from "../../features/addMenu";
import { findWidgetByName } from "../../utils";
import { adminTokenCookieName } from "../../utils/constants";
import { updateWidgetItem } from "../../utils/requests";
import { showToastV2 } from "../WidgetBuilder";

type SettingsNames =
	| "bgColor"
	| "showPhone"
	| "showEmail"
	| "footerText"
	| "hidePoweredBy"
	| "hideStoreLocator"
	| "footerTitle1"
	| "footerTitle2"
	| "footerTitle3";

const footerSettings: SettingItem<SettingsNames>[] = [
	{
		name: "Background Color",
		key: "bgColor",
		type: "color",
	},
	{
		name: "Show Phone",
		key: "showPhone",
		type: "checkbox",
	},
	{
		name: "Show Email",
		key: "showEmail",
		type: "checkbox",
	},
	{
		name: "Footer Text",
		key: "footerText",
		placeholder: "Enter Footer Text",
		type: "text",
	},
	{
		name: "Hide Powered By",
		key: "hidePoweredBy",
		type: "checkbox",
	},
	{
		name: "Hide Store Locator Button",
		key: "hideStoreLocator",
		type: "checkbox",
	},
];

const footerMenuSettings: SettingItem<SettingsNames>[] = [
	{
		name: "Menu 1 Title",
		key: "footerTitle1",
		placeholder: "Company",
		type: "text",
	},
	{
		name: "Menu 2 Title",
		key: "footerTitle2",
		placeholder: "Help Center",
		type: "text",
	},
	{
		name: "Menu 3 Title",
		key: "footerTitle3",
		placeholder: "Terms & Condition",
		type: "text",
	},
];

type NavKeys = "home" | "cart" | "search" | "profile" | "wishlist" | "phone";

const footerBottomItems: {
	name: string;
	key: NavKeys;
}[] = [
	{
		name: "Home",
		key: "home",
	},
	{
		name: "Cart",
		key: "cart",
	},
	{
		name: "Search",
		key: "search",
	},
	{
		name: "Profile",
		key: "profile",
	},
	{
		name: "Wishlist",
		key: "wishlist",
	},
	{
		name: "Phone",
		key: "phone",
	},
];

const FooterSettings = () => {
	const [settings, setSettings] = useState<SettingItems<SettingsNames>>({});

	const [menues, setMenues] = useState<any[]>([]);
	const [menu1, setMenu1] = useState<any>();
	const [menu2, setMenu2] = useState<any>();
	const [menu3, setMenu3] = useState<any>();

	const [selectedFooterNavs, setSelectedFooterNavs] = useState<NavKeys[]>([]);

	const [menuText, setMenuText] = useState<string>("");
	const [showMenu, setShowMenu] = useState<"menu1" | "menu2" | "menu3">();
	const [menuPagination, setMenuPagination] = useState<{
		total: number;
		lastPage: number;
		currentPage: number;
	}>({
		total: 0,
		lastPage: 1,
		currentPage: 1,
	});

	const [themeSettingsW, setThemeSettingsW] = useState<any | null>(null);

	const menuSuggestion = useDetectClickOutside({
		onTriggered: () => setShowMenu(undefined),
	});

	useEffect(() => {
		findWidgetByName("Theme Settings").then((res) => {
			setThemeSettingsW(res);

			setSettings({
				bgColor: res?.settings?.footerSettings?.bgColor || "",
				showPhone: res?.settings?.footerSettings?.showPhone ?? true,
				showEmail: res?.settings?.footerSettings?.showPhone ?? true,
				footerText: res?.settings?.footerSettings?.footerText || "",
				hidePoweredBy:
					res?.settings?.footerSettings?.hidePoweredBy ?? false,
				hideStoreLocator:
					res?.settings?.footerSettings?.hideStoreLocator ?? false,
				footerTitle1: res?.settings?.footerSettings?.footerTitle1 || "",
				footerTitle2: res?.settings?.footerSettings?.footerTitle2 || "",
				footerTitle3: res?.settings?.footerSettings?.footerTitle3 || "",
			});

			if (res?.settings?.footerSettings?.menu1)
				setMenu1({
					name: res?.settings?.footerSettings?.menu1 || "",
					slug: res?.settings?.footerSettings?.menu1 || "",
				});

			if (res?.settings?.footerSettings?.menu2)
				setMenu2({
					name: res?.settings?.footerSettings?.menu2 || "",
					slug: res?.settings?.footerSettings?.menu2 || "",
				});

			if (res?.settings?.footerSettings?.menu3)
				setMenu3({
					name: res?.settings?.footerSettings?.menu3 || "",
					slug: res?.settings?.footerSettings?.menu3 || "",
				});

			setSelectedFooterNavs(
				res?.settings?.footerSettings?.footerBottomNavs || [
					"home",
					"search",
					"profile",
					"phone",
				]
			);
		});

		_axios
			.get(process.env.REACT_APP_BUILDER_URL + `menu`, {
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			})
			.then((res) => {
				setMenues(res.data);
			});
	}, []);

	const handlePublish = () => {
		const footerSettings = {
			...themeSettingsW.settings?.footerSettings,
			...settings,
			menu1: createSlug(menu1?.name),
			menu2: createSlug(menu2?.name),
			menu3: createSlug(menu3?.name),
			footerBottomNavs: selectedFooterNavs,
		};

		updateWidgetItem({
			...themeSettingsW,
			status: "published",
			active: true,
			settings: {
				...themeSettingsW.settings,
				footerSettings,
			},
		})
			.then((res) => {
				showToastV2("Footer updated successfully");
			})
			.catch(() => {
				showToastV2("Footer update failed!", "error");
			});
	};

	const handleToDraft = () => {
		console.log("Safe to draft");
	};

	const handleMenuSearch =
		(type: "menu1" | "menu2" | "menu3") =>
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const target: string = e.target.value;
			setMenuText(target);
			setMenuPagination({
				total: 0,
				lastPage: 1,
				currentPage: 1,
			});
			setShowMenu(type);
		};

	const handleMenuClick = () => {
		setMenuText("");
		setShowMenu(undefined);
	};

	return (
		<div className="p-4">
			<div className="sticky top-[60px] left-0 z-50 bg-white py-4 w-full shadow-md px-2 flex justify-between">
				<h1 className="text-2xl font-bold">Footer Settings</h1>

				<div className="flex gap-4 items-center">
					{/* <button
						className="bg-gray-500 text-white px-4 py-2 rounded-md"
						onClick={handleToDraft}>
						Safe to draft
					</button> */}

					<button
						className="bg-green-500 text-white px-4 py-2 rounded-md"
						onClick={handlePublish}>
						Publish
					</button>
				</div>
			</div>

			<div className="grid grid-cols-3 gap-4">
				<div className="mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">Footer Settings :</p>
					<SettingFormItems
						settings={settings}
						settingItems={footerSettings}
						onChange={(key, value) => {
							setSettings((prev) => ({
								...prev,
								[key]: value,
							}));
						}}
					/>
				</div>

				<div className="col-span-2 mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">Footer Menues :</p>

					<div className="flex gap-4 items-center">
						<p>Menu 1 :</p>
						<div className="flex items-center gap-4">
							<div className="relative">
								<input
									type="text"
									placeholder="Search Menu..."
									value={showMenu === "menu1" ? menuText : ""}
									onChange={handleMenuSearch("menu1")}
								/>
								<div className="bg-gray-100 drop-shadow-md shadow-inner rounded">
									{menues.length > 0 &&
										showMenu === "menu1" && (
											<div
												className="pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar"
												ref={menuSuggestion}
												// onScroll={handleMenuScroll}
											>
												{menues.map((menu, index) => {
													return (
														<div
															key={index}
															onClick={() => {
																handleMenuClick();
																setMenu1(menu);
															}}>
															<div className="flex items-center gap-4 py-2 cursor-pointer">
																<div className="text-sm font-light tracking-wide truncate">
																	{menu.name}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										)}
								</div>
							</div>

							{menu1 && (
								<div className="p-4 py-2 border border-gray-300 rounded-md shadow-sm">
									<p>{menu1.name}</p>
								</div>
							)}
						</div>
					</div>

					<div className="flex gap-4 items-center mt-4">
						<p>Menu 2 :</p>
						<div className="flex items-center gap-4">
							<div className="relative">
								<input
									type="text"
									placeholder="Search Menu..."
									value={showMenu === "menu2" ? menuText : ""}
									onChange={handleMenuSearch("menu2")}
								/>
								<div className="bg-gray-100 drop-shadow-md shadow-inner rounded">
									{menues.length > 0 &&
										showMenu === "menu2" && (
											<div
												className="pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar"
												ref={menuSuggestion}
												// onScroll={handleMenuScroll}
											>
												{menues.map((menu, index) => {
													return (
														<div
															key={index}
															onClick={() => {
																handleMenuClick();
																setMenu2(menu);
															}}>
															<div className="flex items-center gap-4 py-2 cursor-pointer">
																<div className="text-sm font-light tracking-wide truncate">
																	{menu.name}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										)}
								</div>
							</div>

							{menu2 && (
								<div className="p-4 py-2 border border-gray-300 rounded-md shadow-sm">
									<p>{menu2.name}</p>
								</div>
							)}
						</div>
					</div>

					<div className="flex gap-4 items-center mt-4">
						<p>Menu 3 :</p>
						<div className="flex items-center gap-4">
							<div className="relative">
								<input
									type="text"
									placeholder="Search Menu..."
									value={showMenu === "menu3" ? menuText : ""}
									onChange={handleMenuSearch("menu3")}
								/>
								<div className="bg-gray-100 drop-shadow-md shadow-inner rounded">
									{menues.length > 0 &&
										showMenu === "menu3" && (
											<div
												className="pl-3 divide-y max-h-64 min-h-fit overflow-y-scroll no-scrollbar"
												ref={menuSuggestion}
												// onScroll={handleMenuScroll}
											>
												{menues.map((menu, index) => {
													return (
														<div
															key={index}
															onClick={() => {
																handleMenuClick();
																setMenu3(menu);
															}}>
															<div className="flex items-center gap-4 py-2 cursor-pointer">
																<div className="text-sm font-light tracking-wide truncate">
																	{menu.name}
																</div>
															</div>
														</div>
													);
												})}
											</div>
										)}
								</div>
							</div>

							{menu3 && (
								<div className="p-4 py-2 border border-gray-300 rounded-md shadow-sm">
									<p>{menu3.name}</p>
								</div>
							)}
						</div>
					</div>

					<SettingFormItems
						settings={settings}
						settingItems={footerMenuSettings}
						onChange={(key, value) => {
							setSettings((prev) => ({
								...prev,
								[key]: value,
							}));
						}}
					/>
				</div>

				{/* footer bottom nav */}
				<div className="col-span-3 mt-4 border border-gray-300 px-4 py-1">
					<p className="text-lg font-semibold">Footer Bottom Nav :</p>
					<div className="flex gap-2">
						{footerBottomItems.map((item, index) => {
							const isSelected = selectedFooterNavs.includes(
								item.key
							);

							return (
								<button
									type="button"
									onClick={() => {
										if (
											selectedFooterNavs.includes(
												item.key
											)
										) {
											setSelectedFooterNavs((prev) =>
												prev.filter(
													(nav) => nav !== item.key
												)
											);
										} else {
											setSelectedFooterNavs((prev) => [
												...prev,
												item.key,
											]);
										}
									}}
									key={index}
									className={`border border-gray-300 p-2 rounded-md ${
										isSelected
											? "bg-green-600 text-white border-green-600"
											: ""
									}`}>
									{item.name}
								</button>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterSettings;
